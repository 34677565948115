<template>
    <div 
        class="bg-[#3A7E32] h-full w-full flex flex-col items-center fixed z-[150]" 
        :key="updateKey"  
        :class="{
            'scale-[90%] origin-top-left h-[110%]' : screenWidth <= 1470 && screenWidth > 1300,
            'scale-[80%] origin-top-left h-[120%]' : screenWidth <= 1300
        }"    
    >
        <div class="w-[90px] bg-opacity-20 relative flex items-center justify-center select-none rounded-full mt-[60px] mb-[59px]">
            <img src="@/assets/logo.svg" class="w-[78px] scale-150">
            <div class="h-full w-full absolute z-[-10] rounded-xl bg-white blur-2xl"></div>
        </div>
        <!-- <div class="w-[80%] bg-white h-[0.5px] rounded-lg select-none opacity-25"></div> -->
        <div class="w-[220px] scrolltab h-[535%] overflow-y-auto overflow-x-hidden ml-auto">
            <div 
                v-for="tab in menuTabs" 
                @click="chooseInMenu(tab)" 

                class="text-white text-center mt-3 py-[10px] cursor-pointer text-sm select-none items-center"

                :class="
                    {
                        'bg-gradient-to-l from-[#D9D9D93D]' : route.path == tab.path,
                    }
                    " 
            >
                <div class="flex">
                    <img :src="tab.icon" class="mx-2 w-[20px]">
                    <span class="ml-[20px] font-semibold font-base text-[16px]">
                        {{ tab.name }}
                    </span>
                </div>
                <div v-if="tab?.innerPages && tab?.innerPagesView" class="mt-[3px]">
                    <div 
                        v-for="innerTab in tab?.innerPages" 
                        class="flex pl-[69px] mt-[5px] py-[10px] w-[220px]"
                        @click="$router.push(innerTab.path)"

                        :class="
                        {
                            'bg-gradient-to-l from-[#D9D9D93D] font-bold' : route.path == innerTab.path,
                            'font-light' : route.path != innerTab.path,
                        }" 
                    >
                        <span class="font-base lowercase text-[16px] ">
                            {{ innerTab.name }}
                        </span> 
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="mt-[75px]">
            <div class="flex items-center">
                <img class="cursor-pointer" src="@/assets/icons/sidebar_icons/sun_icon.svg">
                <img class="mx-[8px]" src="@/assets/icons/sidebar_icons/arrow.svg">
                <img class="cursor-pointer" src="@/assets/icons/sidebar_icons/moon_icon.svg">
            </div>
        </div> -->
        <div class="h-full"></div>
        <div @click="logout" class="absolute bottom-0 w-full flex justify-center cursor-pointer items-center">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 15.625C12.9264 17.4769 11.3831 19.0494 9.31564 18.9988C8.83465 18.987 8.24013 18.8194 7.05112 18.484C4.18961 17.6768 1.70555 16.3203 1.10956 13.2815C1 12.723 1 12.0944 1 10.8373L1 9.16274C1 7.90561 1 7.27705 1.10956 6.71846C1.70555 3.67965 4.18961 2.32316 7.05112 1.51603C8.24014 1.18064 8.83465 1.01295 9.31564 1.00119C11.3831 0.95061 12.9264 2.52307 13 4.37501" stroke="#F9245A" stroke-width="2" stroke-linecap="round"/>
            <path d="M19 10H8M19 10C19 9.29977 17.0057 7.99153 16.5 7.5M19 10C19 10.7002 17.0057 12.0085 16.5 12.5" stroke="#F9245A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div 
                class="align-middle font-semibold text-center ml-2 py-2 cursor-pointer text-white"
            >
                Вийти
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { useRoute, useRouter } from 'vue-router'

    export default {
        setup() {
            const route = useRoute()
            const router = useRouter()
            const updateKey = ref(uuid.v1())
            const screenWidth = ref(window.innerWidth)
            const menuTabs = [
                {
                    path: '/',
                    name: 'Показники',
                    icon: require('@/assets/icons/sidebar_icons/dashboard_icon.svg')
                },
                {
                    path: '/groups',
                    name: 'Групи',
                    icon: require('@/assets/icons/sidebar_icons/groups_icon.svg')
                },
                {
                    path: '/trainers',
                    name: 'Тренери',
                    icon: require('@/assets/icons/sidebar_icons/trainers_icon.svg')
                },
                {
                    path: '/students',
                    name: 'Учні',
                    icon: require('@/assets/icons/sidebar_icons/players_icon.svg')
                },
                {
                    path: '/admins',
                    name: 'Адміни',
                    icon: require('@/assets/icons/sidebar_icons/trainers_icon.svg')
                },
                {
                    path: '/locations',
                    name: 'Локації',
                    icon: require('@/assets/icons/sidebar_icons/map_icon.svg')
                },
                {
                    path: '/calendar',
                    name: 'Календар',
                    icon: require('@/assets/icons/sidebar_icons/calendar_icon.svg')
                },
                {
                    path: '',
                    name: 'Фінанси',
                    icon: require('@/assets/icons/sidebar_icons/finance_icon.svg'),
                    innerPages: [
                        {
                            path: '/incomes',
                            name: 'Платежі'
                        },
                        {
                            path: '/transactions',
                            name: 'Транзакції'
                        },
                        {
                            path: '/outlays-categories',
                            name: 'Категорії витрат'
                        },
                        {
                            path: '/club-outlays',
                            name: 'Витрати'
                        }
                    ],
                    innerPagesView: false
                },
                {
                    path: '/settings',
                    name: 'Налаштування',
                    icon: require('@/assets/icons/sidebar_icons/settings_icon.svg')
                }
            ]

            watch(window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            const logout = () => {
                sessionStorage.removeItem('access_token')
                sessionStorage.removeItem('refresh_token')
                router.go()
            }

            const chooseInMenu = (tab) => {
                for (let i = 0; i < menuTabs.length; i++) {
                    menuTabs[i].innerPagesView = false
                }
                if (tab.innerPages) {
                    tab.innerPagesView = true
                    updateKey.value = uuid.v1()
                } else {
                    router.push(tab.path)
                }
            }

            return {
                route,
                router,
                menuTabs,
                logout,
                chooseInMenu,
                updateKey,
                screenWidth
            }
        }
    }
</script>

<style>

    .logo-place {
        box-shadow: inset -5px 0px 10px 3px rgba(0, 0, 0, .4);
    }

    .scrolltab::-webkit-scrollbar {
        width: 3px;
        height: 2px;               /* ширина всей полосы прокрутки */
    }

    .scrolltab::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrolltab::-webkit-scrollbar-thumb {
        background-color: rgba(171, 211, 237, 0.25);
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }


</style>