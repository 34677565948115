<template>
    <div 
        class="flex pb-[20px] ml-[50px]"
        :class="{
            'scale-[90%] origin-top-left' : screenWidth <= 1470 && screenWidth > 1300,
            'scale-[80%] origin-top-left' : screenWidth <= 1300
        }"  
    >
        <div class="w-full">
            <div 
                class="pb-[30px] w-max"
                :class="{
                    'ml-auto' : screenWidth > 1840,
                    'mx-auto' : screenWidth <= 1840 
                }"    
            >
        
                <div class="balances-width px-4 flex justify-between">
                    <!-- <div class="px-4 mt-3 flex flex-col sm:flex-row sm:flex-wrap sm:justify-between "> -->
                    <div class="p-4 w-1/4">
                        <!-- <div class="p-4 mb-4 sm:mb-0 sm:w-1/4"> -->
                        <balance-card
                            :loading="balancesLoading"
                            :balance="cashboxBalance + wayforpayBalance + bankBalance + investBalance + trainersStudentsBalances"
                            :title="'всі джерела'"
                            :valueColor="'#F9245A'"
                        />
                    </div>
                    <div class="p-4 w-1/4">
                        <balance-card 
                            :loading="balancesLoading"
                            :balance="cashboxBalance + trainersStudentsBalances"
                            :title="'готівка'"
                            :valueColor="'#4EAE3B'"
                        />
                    </div>
                    <div class="p-4 w-1/4 relative">
                        <balance-card
                            @click="showDetailCashless = true" 
                            class="cursor-pointer select-none"
                            :loading="balancesLoading"
                            :balance="wayforpayBalance + bankBalance"
                            :title="'безготівка'"
                            :valueColor="'#ABD3EC'"
                        />
                        <div v-if="showDetailCashless" @click="showDetailCashless = false" class="bg-[#252526] cursor-pointer select-none border-[#D9D9D9] absolute top-[16px] w-[262px] h-[97.5px] border-[1px] border-opacity-10 text-center px-[20px] pt-[9px] pb-[7px] rounded-[10px] flex flex-col items-center justify-center">
                            <div class="mx-[20px] flex items-center justify-start">
                                <div class="text-[15px] text-[#9FA2B4] mr-[10px] w-[50px]">Wayforpay</div>
                                <div class="text-[21px] text-[#ABD3EC] font-semibold w-[140px]"><span class="text-[16px]">₴</span>{{ formatNumber(wayforpayBalance) }}</div>
                            </div>
                            <div class="mx-[20px] flex items-center">
                                <div class="text-[15px] text-[#9FA2B4] mr-[10px] w-[50px]">Банк</div>
                                <div class="text-[21px] text-[#ABD3EC] font-semibold w-[140px]"><span class="text-[16px]">₴</span>{{ formatNumber(bankBalance) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 w-1/4">
                        <balance-card 
                            :loading="balancesLoading"
                            :balance="investBalance"
                            :title="'інвестиції'"
                            :valueColor="'#DFF726'"
                        />
                    </div>
                </div>
                
        
                <div class="statics-width px-4 flex justify-between">
                    <div class="px-4 w-1/4">
                        <StaticAndFinanceBlock 
                            :loading="subParamsLoading"
                            :value="meanRealIncomeFromStudent"
                            :title="'середній платіж'"
                            type="finance"
                        />
                    </div>
                    <div class="px-4 w-1/4">
                        <StaticAndFinanceBlock 
                            :loading="balancesLoading"
                            :value="trainersStudentsBalances"
                            :title="'готівка у тренерів'"
                            type="finance"
                        />
                    </div>
                    <div class="px-4 w-1/4">
                        <StaticAndFinanceBlock 
                            :loading="subParamsLoading"
                            :value="countStudentsWithOverdueTariffs"
                            :title="'учні з просроченим тарифом'"
                            type="static"
                        />
                    </div>
                    <div class="px-4 w-1/4">
                        <StaticAndFinanceBlock
                            :loading="subParamsLoading"
                            :value="percentTrainingsWithAdditionalFilesInCurrentMonth"
                            :title="'тренувань з конспектом'"
                            type="percent"
                        />
                    </div>
                </div>
        
                <div class="mt-[30px] incomes-by-sources mx-[30px] py-4 rounded-[20px] px-4 flex justify-between items-center">
                    <IncomesBySource />
        
                    <div class="relative flex h-full flex-col justify-start">
                        <PercentageIncomesBySource />
                    </div>
                </div>
        
                <div class="px-4 mt-[30px] incomes-groups-container mx-[30px] py-4 rounded-[20px]">
                    <IncomesByGroups />
                </div>
        
                <div class="px-4 mt-[30px] mx-[30px] py-4 rounded-[20px] outlays-width">
                    <div class="font-semibold text-[#fff]">
                        ВИТРАТИ
                    </div>
                    <div class="flex justify-between mt-[-50px] relative">
                        <BarChart 
                            v-if="clubOutlaysChartDataLoaded"
                            class="w-max relative top-[70px] left-[15px]"
                            :data="clubOutlaysChartData"
                            :lables="['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Черв', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд']"
                            :barColor="'#F9245A'"
                            :height="290"
                            :width="outlaysBarChartWidth"
                            :key="clubOutlaysChartKey"
                        />
                        <div v-else class="w-[1000px] h-[300px]">
                            <div class="z-[150] absolute w-full h-full top-[50%] left-[38%]">
                                <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
                            </div>
                        </div>
                        <div v-if="clubOutlaysChartDataLoaded">
                            <div>
                                <select v-model="outlaysCircleDiagramDate" name="month" class="outline-none rounded-[4px] w-[144px] px-[5px] py-[0.6px] text-start border-[#A4A6B3] text-[#FFF] text-[13px] relative top-[18px] left-[70px] cursor-pointer border-[1px] bg-transparent z-[150]">
                                    <option value="1.2023" class="bg-[#252526]">січень 2023</option>
                                    <option value="2.2023" class="bg-[#252526]">лютий 2023</option>
                                    <option value="3.2023" class="bg-[#252526]">березень 2023</option>
                                    <option value="4.2023" class="bg-[#252526]">квітень 2023</option>
                                    <option value="5.2023" class="bg-[#252526]">травень 2023</option>
                                    <option value="6.2023" class="bg-[#252526]">червень 2023</option>
                                    <option value="7.2023" class="bg-[#252526]">липень 2023</option>
                                    <option value="8.2023" class="bg-[#252526]">серпень 2023</option>
                                    <option value="9.2023" class="bg-[#252526]">вересень 2023</option>
                                    <option value="10.2023" class="bg-[#252526]">жовтень 2023</option>
                                    <option value="11.2023" class="bg-[#252526]">листопад 2023</option>
                                    <option value="12.2023" class="bg-[#252526]">грудень 2023</option>
                                </select>
                            </div>
                            <CircleDiagramWithGap :key="showCircleWithGap" :width="outlaysCircleChartWidth" :height="outlaysCircleChartHeight" :segments="clubOutlaysDiagramData" class="w-max h-max ml-[35px]"/>
                        </div>
                        <div v-else class="w-[350px] h-[300px] relative">
                            <div class="z-[150] absolute w-full h-full top-[50%] left-[65%]">
                                <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="px-8 flex justify-between mt-[50px]">
                    <div class="px-4 py-4 rounded-[20px]">
                        <div class="font-semibold mb-[30px] text-[#fff]">
                            ПРИБУТОК
                        </div>
                        <div class="relative flex justify-between z-[30]">
                            <TwoLinesBarChart 
                                v-if="profitChartDataLoaded"
                                :chartData="{
                                    incomes: profitChartIncomesData,
                                    outlays: profitChartOutlaysData,
                                    earnings: profitChartData
                                }" 
                                :key="twoLinesChartLoading" 
                                :width="twoLinesChartWidth" 
                                :height="twoLinesChartHeight"
                            />
                            <div v-else class="w-[850px] h-[220px] relative">
                                <div class="z-[150] absolute w-full h-full top-[50%] left-[50%]">
                                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="relative right-[5px] py-4 rounded-[20px]">
                        <div class="font-semibold text-center text-[#fff]">
                                ЗА ВЕСЬ РІК
                            </div>
                        <div class="all-income-params flex flex-col justify-between pt-[35px] pb-[30px]">
                            <div class="text-center text-[#4EAE3B] relative top-[12px]">
                                <div v-if="profitChartSumLoaded" class="text-[21px] font-semibold">{{ profitChartIncomesSum.toLocaleString() }}</div>
                                <div v-else class="relative bottom-[5px] mx-auto mb-[6px] rounded-full animate-pulse mt-[5px] text-2xl w-[70px] h-[20px] bg-[#4EAE3B] bg-opacity-25"></div>
                                <div class="leading-[5px] text-[12px] relative top-[10px]">дохід</div>
                            </div>
                            <div class="text-center text-[#DFF726] relative top-[px]">
                                <div v-if="profitChartSumLoaded" class="text-[21px] relative bottom-[10px] font-semibold">{{ profitChartDataSum.toLocaleString() }}</div>
                                <div v-else class="relative bottom-[10px] mx-auto mb-[10px] rounded-full animate-pulse mt-[5px] text-2xl w-[70px] h-[20px] bg-[#DFF726] bg-opacity-25"></div>
                                <div class="leading-[5px] text-[12px]">прибуток</div>
                            </div>
                            <div class="text-center text-[#F9245A] relative bottom-[40px]">
                                <div v-if="profitChartSumLoaded" class="text-[21px] font-semibold">{{ profitChartOutlaysSum.toLocaleString() }}</div>
                                <div v-else class="relative bottom-[5px] mx-auto mb-[6px] rounded-full animate-pulse mt-[5px] text-2xl w-[70px] h-[20px] bg-[#F9245A] bg-opacity-25"></div>
                                <div class="leading-[5px] text-[12px] relative top-[10px]">витрати</div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="px-8 mt-[30px] w-[1200px]">
                    <div class="font-semibold text-[#fff]">
                        КІЛЬКІСНІ ПОКАЗНИКИ
                    </div>
                    <div class="mt-[30px] flex justify-between text-[#fff]">
                        <div class="flex">
                            <div class="bg-[#252526] border-[#D9D9D9] border-[1px] border-opacity-10 text-center w-[90px] h-[90px] mr-[20px] px-[10px] pt-[20px] rounded-[10px]">
                                <div v-if="studentsInGroupsLoaded" class="font-semibold text-[24px]">{{ allTrainers.length }}</div>
                                <div v-else class="mx-auto mb-[9px] rounded-full animate-pulse mt-[6px] text-2xl w-[69px] h-[22px] bg-[#9CA3AF] bg-opacity-25"></div>
                                <div class="text-[14px] relative bottom-[7px]">тренерів</div>
                            </div>
                            <div class="bg-[#252526] border-[#D9D9D9] border-[1px] border-opacity-10 text-center w-[90px] h-[90px] px-[10px] pt-[20px] rounded-[10px]">
                                <div v-if="studentsInGroupsLoaded" class="font-semibold text-[24px]">{{ allGroups.length }}</div>
                                <div v-else class="mx-auto mb-[9px] rounded-full animate-pulse mt-[6px] text-2xl w-[69px] h-[22px] bg-[#9CA3AF] bg-opacity-25"></div>
                                <div class="text-[14px] relative bottom-[7px]">груп</div>
                            </div>
                        </div>
        
                        <div class="bg-[#252526] border-[#D9D9D9] border-[1px] border-opacity-10 h-[90px] items-center text-center w-[600px] px-[10px] py-[5px] flex rounded-[10px]">
                            <div class="w-max my-[10px] h-[70px] pl-[20px] pr-[30px]">
                                <div v-if="studentsInGroupsLoaded" class="font-semibold leading-[55px] text-[24px]">{{ allStudentsCount }}</div>
                                <div v-else class="mx-auto mb-[20px] w-[50px] rounded-full animate-pulse mt-[15px] text-2xl h-[20px] bg-[#9CA3AF] bg-opacity-25"></div>
                                <div class="leading-[1px] text-[14px] mb-[5px] relative bottom-[7px]">учнів</div>
                            </div>  
                            <div class="h-[60%] bg-[#fff] w-px"></div>
                            <div v-if="studentsInGroupsLoaded" class="flex overflow-x-auto w-[600px] pb-[10px] pt-[8px] scrolltab ml-[10px]">
                                <div v-if="studentsInGroups.length > 0" v-for="group in studentsInGroups" class="w-[50px] px-[10px] text-center">
                                    <div class="font-semibold text-[18px] text-center w-[50px]">{{ group.students_count }}</div>
                                    <div class="w-[50px] text-[14px] truncate text-center">{{ group.group_name }}</div>
                                </div>
                                <div v-else class="w-full opacity-50">Нема даних</div>
                            </div>
                            <div v-else class="w-[700px] h-[30px] relative">
                                <div class="z-[150] absolute w-full h-full top-[5px] left-[50%]">
                                    <div class="w-7 h-7 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
        
                <div class="px-8 mt-[80px] mb-[50px]">
                    <div class="font-semibold text-[#fff]">
                        АНАЛІТИКА ПО ТРЕНЕРАХ
                    </div>
                    <div class="w-[100%] mt-[30px]">
                        <table class="w-full">
                            <thead>
                                <tr class="text-[#fff] h-[14px]">
                                    <th class="border-x-[1px] leading-[0] border-opacity-[45%] border-[#fff] text-[12px] font-normal w-[8%]">фото</th>
                                    <th class="border-x-[1px] leading-[0] border-opacity-[45%] border-[#fff] text-[12px] font-normal w-[14%] text-center">тренер</th>
                                    <th class="border-x-[1px] leading-[0] border-opacity-[45%] border-[#fff] text-[12px] font-normal text-center w-[7%]">групи</th>
                                    <th class="border-x-[1px] leading-[0] border-opacity-[45%] border-[#fff] text-[12px] font-normal text-center w-[7%]">гравці</th>
                                    <th class="border-x-[1px] leading-[0] border-opacity-[45%] border-[#fff] text-[12px] font-normal">динаміка проведених тренувань</th>
                                    <th class="border-x-[1px] leading-[0] border-opacity-[45%] border-[#fff] text-[12px] font-normal">доходність по тренерах</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="6" class="h-[20px]"></td>
                                </tr>
                                <trainer-row 
                                    v-if="trainersDataLoaded"
                                    v-for="stat in trainersGroupsStats" 
                                    :name="stat.trainer_name"
                                    :groups="stat.groups_count"
                                    :students="stat.students_count"    
                                    :photo="stat.photo"
                                    :completed_trainings_count="stat.completed_trainings_count"
                                    :trainer_groups_incomes="stat.trainer_groups_incomes"
                                />
                                <tr v-else>
                                    <td colspan="6">
                                        <div class="w-full h-[200px] relative">
                                            <div class="z-[150] absolute w-full h-full top-[50%] left-[50%]">
                                                <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent "></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        
            </div>
        </div>
        <div v-if="screenWidth > 1840" class="border-l-[1px] ml-[51px] border-gray-400 border-opacity-20 mt-[10px] text-[#fff] pl-[70px]">
            <div class="w-[80%]">
                <div class="text-[12px] font-light">
                    <span class="text-[30px] leading-[0] relative top-[5px]">·</span> У блоці надходжень показані фактичні дані по  наявним коштам у клубі станом на цей момент. <br><br>
                    Середній платіж - сума оплат у поділена на  кількість платежів<br><br>
    
                    Готівка у тренерів - це сума готівки на руках у  всіх тренерів<br><br>
    
                    Сума списань - показує, скільки коштів отримано у цьому місяці за проведені тренування, без врахування авансових платежів.<br><br>
    
                    Кількість списань показує: скільки учнів тренувалися та оплатили тренування у поточному місяці<br><br>
                </div>  
                <div class="text-[12px] font-light">
                    <span class="text-[30px] leading-[0] relative top-[5px]">·</span> Діаграма ДОХОДИ ПО ДЖЕРЕЛАХ демонструє динаміку поступлення коштів по кожному джерелу, а також сумарну - по всім джерелам ( червоним кольором)<br><br>
    
                    <span class="text-[30px] leading-[0] relative top-[5px]">·</span> Кругова діаграма показує розподіл в процентах по видам доходів
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
    import { getServiceBalanceByLabel, getAllTrainerStudentBalanceState, 
        getAllCashFromAdmins, getAllInvestFromAdmins, 
        getSumOfAllVirtualOutlays, getAllVirtualOutlaysCount, 
        getMeanRealIncomeFromStudent, getStatisticsClubOutlaysByYear, getYearIncomesBySources,
        getAllGroups, getAllTrainers, getStudentsCountByGroupId, getTrainerCompletedTrainingsByYear, 
        getAllIncomesByGroupInYear, getCountStudentsWithOverdueTariffs, getPercentTrainingsWithAdditionalFilesInCurrentMonth } from '@/services/apiRequests'
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import BalanceCard from './components/BalanceCard.vue'
    import StaticAndFinanceBlock from './components/StaticAndFinanceBlock.vue'
    import CircleDiagram from './components/CircleDiagram.vue'
    import BarOverlineChart from './components/BarOverlineChart.vue'
    import BarChart from './components/BarChart.vue'
    import CircleDiagramWithGap from './components/CircleDiagramWithGap.vue'
    import TwoLinesBarChart from './components/TwoLinesBarChart.vue'
    import MinLineChart from './components/MinLineChart.vue'
    import MinBarChart from './components/MinBarChart.vue'

    import IncomesBySource from './components/DashboardComponents/IncomesBySource.vue'
    import PercentageIncomesBySource from './components/DashboardComponents/PercentageIncomesBySource.vue'
    import IncomesByGroups from './components/DashboardComponents/IncomesByGroups.vue'
    import TrainerRow from './components/DashboardComponents/TrainerRow.vue'

    export default {
        name: 'Finance', 

        setup() {
            const wayforpayBalance = ref(0)
            const bankBalance = ref(0)
            const cashboxBalance = ref(0)
            const investBalance = ref(0)
            const trainersStudentsBalances = ref(0)
            const balancesLoading = ref(true)

            const sumOfAllVirtualOutlays = ref(0)
            const allVirtualOutlaysCount = ref(0)
            const meanRealIncomeFromStudent = ref(0)
            const subParamsLoading = ref(true)

            const showCircleWithGap = ref(uuid.v4())
            const screenWidth = ref(window.innerWidth)

            const clubOutlaysStatistics = ref([])
            const clubOutlaysStatisticsYear = ref(new Date().getFullYear())
            const clubOutlaysStatisticsMonth = ref(new Date().getMonth())
            const clubOutlaysChartData = ref([])
            const clubOutlaysDiagramData = ref([])
            const clubOutlaysChartKey = ref(uuid.v4())

            const profitChartYear = ref(new Date().getFullYear())
            const profitChartIncomesData = ref([])
            const profitChartIncomesSum = ref(0)
            const profitChartOutlaysData = ref([])
            const profitChartOutlaysSum = ref(0)
            const profitChartData = ref([])
            const profitChartDataSum = ref(0)
            const profitChartDataLoaded = ref(false)
            const profitChartSumLoaded = ref(false)
            const allTrainers = ref([])
            const allGroups = ref([])

            const countStudentsWithOverdueTariffs = ref(0)
            const percentTrainingsWithAdditionalFilesInCurrentMonth = ref(0)

            const showDetailCashless = ref(false)

            //outlays circle chart
            const outlaysCircleChartHeight = ref(330)
            const outlaysCircleChartWidth = ref(300)

            const resizeOutlaysCircleChart = () => {
                if (screenWidth.value < 1410) {
                    outlaysCircleChartHeight.value = 230
                    outlaysCircleChartWidth.value = 200
                } else {
                    outlaysCircleChartHeight.value = 330
                    outlaysCircleChartWidth.value = 300
                }
                showCircleWithGap.value = uuid.v4()
            }

            watch(() => window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            watch(screenWidth, () => {
                resizeOutlaysCircleChart()
            })

            //outlays block
            const outlaysBarChartHeight = ref(127)
            const outlaysBarChartWidth = ref(790)

            const resizeOutlaysBlock = () => {
                if (screenWidth.value < 1410) {
                    outlaysBarChartHeight.value = 300
                    outlaysBarChartWidth.value = 600
                } else {
                    outlaysBarChartHeight.value = 367
                    outlaysBarChartWidth.value = 790
                }
            }

            watch(screenWidth, () => {
                resizeOutlaysBlock()
            })

            //two lines chart
            const twoLinesChartHeight = ref(400)
            const twoLinesChartWidth = ref(790)
            const twoLinesChartLoading = ref(uuid.v4())
            const outlaysCircleDiagramDate = ref('11.2023')

            const resizeTwoLinesChart = () => {
                if (screenWidth.value < 1410) {
                    twoLinesChartHeight.value = 300
                    twoLinesChartWidth.value = 630
                } else {
                    twoLinesChartHeight.value = 400
                    twoLinesChartWidth.value = 790
                }
                twoLinesChartLoading.value = uuid.v4()
            }

            watch(screenWidth, () => {
                resizeTwoLinesChart()
            })
 

            const allYearsClubOutlaysStatistics = ref([])
            const clubOutlaysChartDataLoaded = ref(false)

            const updateClubOutlaysDiagram = () => {
                let segments = []
                const currentOutlayStatisticMonth = allYearsClubOutlaysStatistics.value.find(outlay => outlay.date_format == outlaysCircleDiagramDate.value)
                console.log('currentOutlayStatisticMonth ------ ', currentOutlayStatisticMonth)

                if (currentOutlayStatisticMonth) {
                    currentOutlayStatisticMonth.categories.forEach(category => {
                        segments.push({
                            label: category.category_name,
                            value: category.outlays_sum
                        })
                    })
                    clubOutlaysDiagramData.value = segments
                } else {
                    clubOutlaysDiagramData.value = []
                }
                showCircleWithGap.value = uuid.v4()
            }

            const updateOutlaysChart = async () => {
                const clubOutlaysStatisticsRes = await getStatisticsClubOutlaysByYear(clubOutlaysStatisticsYear.value)
                allYearsClubOutlaysStatistics.value = clubOutlaysStatisticsRes 

                for (let i = 1; i <= 12; i++) {
                    let currentOutlay = allYearsClubOutlaysStatistics.value.find(outlay => outlay.date_format.split('.')[0] === `${i}`)
                    if (currentOutlay) {
                        clubOutlaysChartData.value.push(currentOutlay.total_outlays_sum) 
                    } else {
                        clubOutlaysChartData.value.push(0)
                    }
                }

                updateClubOutlaysDiagram()

                clubOutlaysChartDataLoaded.value = true
                clubOutlaysChartKey.value = uuid.v4()
                showCircleWithGap.value = uuid.v4()
            }

            watch(outlaysCircleDiagramDate, () => {
                updateClubOutlaysDiagram()
            })

            const updateProfitChart = async () => {
                const clubOutlaysStatisticsRes = await getStatisticsClubOutlaysByYear(profitChartYear.value)
                const yearIncomesStatistics = await getYearIncomesBySources(profitChartYear.value)

                
                for (let i = 1; i <= 12; i++) {
                    const currentMonthIncome = yearIncomesStatistics.find(income => income.date_formatted.split('.')[0] == i)
                    if (currentMonthIncome) {
                        profitChartIncomesData.value.push(currentMonthIncome.all_incomes)    
                    } else {
                        profitChartIncomesData.value.push(0)
                    }

                    const currentMonthOutlay = clubOutlaysStatisticsRes.find(outlay => outlay.date_format.split('.')[0] == i)
                    if (currentMonthOutlay) {
                        profitChartOutlaysData.value.push(currentMonthOutlay.total_outlays_sum)    
                    } else {
                        profitChartOutlaysData.value.push(0)
                    }

                    profitChartData.value.push(profitChartIncomesData.value[profitChartIncomesData.value.length - 1] - profitChartOutlaysData.value[profitChartOutlaysData.value.length - 1])
                }

                profitChartDataLoaded.value = true

                profitChartIncomesSum.value = profitChartIncomesData.value.reduce((sum, current) => sum + current, 0)
                profitChartOutlaysSum.value = profitChartOutlaysData.value.reduce((sum, current) => sum + current, 0)
                profitChartDataSum.value = profitChartData.value.reduce((sum, current) => sum + current, 0)
                profitChartSumLoaded.value = true
            }

            const trainersDataLoaded = ref(false)
            const getAllTrainersAndGroups = async () => {
                const allTrainersRes = await getAllTrainers()
                allTrainers.value = allTrainersRes.data
                const allGroupsRes = await getAllGroups()
                allGroups.value = allGroupsRes.data
            }

            const studentsInGroups = ref([])
            const allStudentsCount = ref(0)
            const studentsInGroupsLoaded = ref(false)
            const countStudentsInGroups = async () => {
                let studentsCount = 0

                for (let i = 0; i < allGroups.value.length; i++) {
                    const res = await getStudentsCountByGroupId(allGroups.value[i].group_id)
                    studentsInGroups.value.push({
                        group_name: allGroups.value[i].name,
                        students_count: res.data
                    })
                    
                    studentsCount += res.data
                }

                allStudentsCount.value = studentsCount
                studentsInGroupsLoaded.value = true
            }

            const trainersGroupsStats = ref([])
            const trainersGroupsStatistics = async () => {
                let trainersGroupsStatistics = []
                const incomesRes = await getAllIncomesByGroupInYear(new Date().getFullYear())
                const incomes = incomesRes.data
                console.log('incomes ------------- ', incomes)
                for (let i = 0; i < allTrainers.value.length; i++) {
                    let groupsCount = 0
                    let studentsGroupsCount = 0
                    let trainersGroupIds = []
                    for (let j = 0; j < allGroups.value.length; j++) {
                        if (allGroups.value[j].trainer_id === allTrainers.value[i].trainer_id) {
                            trainersGroupIds.push(allGroups.value[j].group_id)
                            groupsCount++
                            studentsGroupsCount += studentsInGroups.value.find(studentsInGroup => studentsInGroup.group_name === allGroups.value[j].name).students_count
                        }
                    }
                 
                    const completedTrainingsRes = await getTrainerCompletedTrainingsByYear(new Date().getFullYear(), allTrainers.value[i].trainer_id)
                    console.log('completedTrainingsRes ------------- ', completedTrainingsRes)

                    const trainerGroupsIncomes = []

                    for (let i = 1; i <= 12; i++) {
                        const currentMonthIncome = incomes.find(income => i <= 9 ? income.date_formatted.split('.')[0].includes(`0${i}`) : income.date_formatted.split('.')[0].includes(`${i}`))
                        let allCurrentMonthIncomes = 0

                        if (currentMonthIncome) {
                            currentMonthIncome.groups.forEach(group => {
                                if (trainersGroupIds.includes(group.group_id)) {
                                    allCurrentMonthIncomes += group.all_incomes
                                }
                            })
                        }
                        
                        trainerGroupsIncomes.push(allCurrentMonthIncomes)
                    }

                    trainersGroupsStatistics.push({
                        photo: allTrainers.value[i].photo,
                        trainer_name: allTrainers.value[i].surname + ' ' + allTrainers.value[i].name[0] + '.',
                        groups_count: groupsCount,
                        students_count: studentsGroupsCount,
                        completed_trainings_count: completedTrainingsRes.data,
                        trainer_groups_incomes: trainerGroupsIncomes
                    })
                }

                trainersDataLoaded.value = true
                return trainersGroupsStatistics
            }

            const formatNumber = (number) => {
                const parts = number.toFixed(2).toString().split('.');
                const integerPart = parts[0];
                const decimalPart = parts[1];

                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                const formattedNumber = formattedInteger + '.' + decimalPart;

                return formattedNumber;
            }

            onMounted(async () => {
                const wayforpayBalanceRes = await getServiceBalanceByLabel('Wayforpay')
                wayforpayBalance.value = wayforpayBalanceRes.state
                const bankBalanceRes = await getServiceBalanceByLabel('Bank')
                bankBalance.value = bankBalanceRes.state
                const cashboxBalanceRes = await getAllCashFromAdmins()
                cashboxBalance.value = cashboxBalanceRes.balance_state
                const investBalanceRes = await getAllInvestFromAdmins()
                investBalance.value = investBalanceRes.balance_state
                const allTrainerStudentsBalanceRes = await getAllTrainerStudentBalanceState('AllTrainerStudents')
                trainersStudentsBalances.value = allTrainerStudentsBalanceRes.balance_state
                balancesLoading.value = false

                const sumOfAllVirtualOutlaysRes = await getSumOfAllVirtualOutlays()
                sumOfAllVirtualOutlays.value = sumOfAllVirtualOutlaysRes.sum
                const allVirtualOutlaysCountRes = await getAllVirtualOutlaysCount()
                allVirtualOutlaysCount.value = allVirtualOutlaysCountRes.count
                const meanRealIncomeFromStudentRes = await getMeanRealIncomeFromStudent()
                meanRealIncomeFromStudent.value = meanRealIncomeFromStudentRes.mean
                const countStudentsWithOverdueTariffsRes = await getCountStudentsWithOverdueTariffs()
                countStudentsWithOverdueTariffs.value = countStudentsWithOverdueTariffsRes
                const percentTrainingsWithAdditionalFilesInCurrentMonthRes = await getPercentTrainingsWithAdditionalFilesInCurrentMonth()
                percentTrainingsWithAdditionalFilesInCurrentMonth.value = percentTrainingsWithAdditionalFilesInCurrentMonthRes
                console.log('percentTrainingsWithAdditionalFilesInCurrentMonth.value ------------- ', percentTrainingsWithAdditionalFilesInCurrentMonth.value)
                console.log('countStudentsWithOverdueTariffs.value ------------- ', countStudentsWithOverdueTariffs.value)
                subParamsLoading.value = false

                await updateOutlaysChart()
                await updateProfitChart()
                await getAllTrainersAndGroups()
                await countStudentsInGroups()

                trainersGroupsStats.value = await trainersGroupsStatistics()

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                resizeOutlaysBlock()
                resizeOutlaysCircleChart()
                resizeTwoLinesChart()
            })

            return {
                wayforpayBalance,
                bankBalance,
                cashboxBalance,
                investBalance,
                trainersStudentsBalances,
                balancesLoading,
                sumOfAllVirtualOutlays,
                allVirtualOutlaysCount,
                meanRealIncomeFromStudent,
                subParamsLoading,
                screenWidth,
                outlaysBarChartHeight,
                outlaysBarChartWidth,
                outlaysCircleChartHeight,
                outlaysCircleChartWidth,
                showCircleWithGap,
                twoLinesChartHeight,
                twoLinesChartWidth,
                twoLinesChartLoading,
                clubOutlaysChartData,
                clubOutlaysChartKey,
                clubOutlaysDiagramData,

                profitChartOutlaysData,
                profitChartIncomesData,
                profitChartData,

                profitChartOutlaysSum,
                profitChartIncomesSum,
                profitChartDataSum,

                allTrainers,
                allGroups,
                allStudentsCount,
                studentsInGroups,
                trainersGroupsStats,

                clubOutlaysChartDataLoaded,
                profitChartDataLoaded,
                profitChartSumLoaded,
                studentsInGroupsLoaded,
                trainersDataLoaded,
                outlaysCircleDiagramDate,
                formatNumber,
                showDetailCashless,
                countStudentsWithOverdueTariffs,
                percentTrainingsWithAdditionalFilesInCurrentMonth
            }
        },

        components: {
            BalanceCard,
            StaticAndFinanceBlock,
            CircleDiagram,
            BarOverlineChart,
            BarChart,
            CircleDiagramWithGap,
            TwoLinesBarChart,
            MinLineChart,
            MinBarChart,
            IncomesBySource,
            PercentageIncomesBySource,
            IncomesByGroups,
            TrainerRow
        }
    }
</script>

<style lang="scss" scoped>

.scrolltab::-webkit-scrollbar {
    width: 3px;
    height: 2px;               /* ширина всей полосы прокрутки */
}

.scrolltab::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrolltab::-webkit-scrollbar-thumb {
    background-color: rgba(171, 211, 237, 0.25);
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}


@media (min-width: 1410px) and (max-width: 8000px) {
    .balances-width {
        @apply w-[1205px];
    }

    .statics-width {
        @apply w-[1205px];
    }

    .incomes-by-sources {
        @apply w-[1110px];
    }

    .incomes-groups-container {
        @apply w-[1110px];
    }

    .all-income-params {
        @apply w-[250px] h-[355px];
    }

    .outlays-width {
        @apply w-[1100px];
    }
}

@media (min-width: 0px) and (max-width: 1410px) {
    .balances-width {
        @apply w-[1205px] ml-[10px];
    }

    .statics-width {
        @apply w-[1205px] ml-[10px];
    }

    .incomes-by-sources {
        @apply w-[900px];
    }

    .incomes-groups-container {
        @apply w-[900px];
    }

    .all-income-params {
        @apply w-[250px] h-[330px];
    }

    .outlays-width {
        @apply w-[880px];
    }
}

</style>

